import React from "react"
import { Box, Text, VStack, Flex, Heading, Image } from "@chakra-ui/react"
import { useTranslation } from "gatsby-plugin-react-i18next"

import UsageStep1Image from "../../assets/images/usage-steps/usage-step-1.svg"
import UsageStep2Image from "../../assets/images/usage-steps/usage-step-2.svg"

const usageSteps = [{ image: UsageStep1Image }, { image: UsageStep2Image }]

const HowItWorks = () => {
  const { t } = useTranslation()

  return (
    <Box
      as="section"
      width="full"
      backgroundColor="ahuaYellow.70"
      color="white"
    >
      <Box mx="auto" maxW="4xl" px="6" py="20">
        <Heading size="xl" textAlign="center" fontWeight="400" mb="20">
          {t(`home-page.how-it-works-section.heading`)}
        </Heading>
        <VStack spacing={{ base: "12", sm: "10" }} alignItems="flex-start">
          {usageSteps.map(({ image }, index) => (
            <Flex
              key={`how-it-works-${index + 1}`}
              direction={{ base: "column", sm: "row" }}
              width="full"
              alignItems="center"
            >
              <Flex width={{ base: "100%", sm: "30%" }} justifyContent="center">
                <Image
                  boxSize="10rem"
                  filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
                  src={image}
                  mb={{ base: "4", sm: "0" }}
                  px="4"
                  alt={`${t(
                    `home-page.how-it-works-section.usage-steps.${index}.name`
                  )} logo`}
                />
              </Flex>
              <Flex
                width={{ base: "100%", sm: "60%" }}
                textAlign={{ base: "center", sm: "inherit" }}
                direction="column"
                justifyContent="center"
              >
                <Heading fontWeight="400" fontSize="2rem" mb="4">
                  {t(
                    `home-page.how-it-works-section.usage-steps.${index}.name`
                  )}
                </Heading>
                <Text fontSize="1.2rem">
                  {" "}
                  {t(
                    `home-page.how-it-works-section.usage-steps.${index}.description`
                  )}
                </Text>
              </Flex>
            </Flex>
          ))}
        </VStack>
      </Box>
    </Box>
  )
}

export default HowItWorks
