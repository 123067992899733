import React from "react"
import { Box, Flex } from "@chakra-ui/react"

import Header from "./Header"
import Footer from "./Footer"

const Layout = ({ children }) => {
  return (
    <Flex flexDirection="column" minHeight="100vh">
      <Header />
      <Box as="main" width="full" flexGrow="1">
        {children}
      </Box>
      <Footer />
    </Flex>
  )
}

export default Layout
