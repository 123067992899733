import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Box, Text, VStack, StackDivider, Heading } from "@chakra-ui/react"
import { useTranslation } from "gatsby-plugin-react-i18next"

import { headerHeight } from "../../utils/constant"

const query = graphql`
  {
    teamMemberImages: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativeDirectory: { eq: "teams" }
        name: { regex: "/team-member-/" }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            height: 150
            width: 150
            backgroundColor: "transparent"
            transformOptions: { fit: COVER }
          )
        }
      }
    }
  }
`

const Teams = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(query)
  const teamMemberImages = data?.teamMemberImages?.nodes

  return (
    <Box
      as="section"
      id="Teams"
      sx={{ scrollMarginTop: headerHeight }}
      width="full"
    >
      <Box textAlign="center" mx="auto" maxW="3xl" px="6" py="20">
        <Heading
          color="ahuaYellow.90"
          size="xl"
          textAlign="center"
          fontWeight="400"
          mb="8"
        >
          {t(`home-page.teams-section.heading`)}
        </Heading>
        <Text fontSize="1.4rem" mt="2" mb="20" color="gray.600">
          {t(`home-page.teams-section.subheading`)}
        </Text>
        <VStack
          spacing="12"
          divider={
            <StackDivider
              width="70%"
              borderColor="gray.400"
              alignSelf="center"
            />
          }
        >
          {teamMemberImages.map((teamMemberImage, index) => (
            <Box key={`team-member-${index + 1}`}>
              <Box mb="10">
                <Box
                  as={GatsbyImage}
                  image={getImage(teamMemberImage)}
                  borderRadius="full"
                  imgStyle={{ borderRadius: "100%" }}
                  alt={`coffeeclub team member ${index + 1}`}
                />
              </Box>
              <Heading fontWeight="400" size="lg" mb="2">
                {t(`home-page.teams-section.members.${index}.name`)}
              </Heading>
              <Heading fontWeight="300" size="md" color="gray.600" mb="10">
                {t(`home-page.teams-section.members.${index}.title`)}
              </Heading>
              <VStack spacing="6">
                {t(`home-page.teams-section.members.${index}.descriptions`, {
                  defaultValue: [],
                  returnObjects: true,
                }).map((description, index) => (
                  <Text
                    fontSize="1.2rem"
                    key={`team-member-description-${index + 1}`}
                  >
                    {description}
                  </Text>
                ))}
              </VStack>
            </Box>
          ))}
        </VStack>
      </Box>
    </Box>
  )
}

export default Teams
