import React from "react"
import { Box, VStack, Heading, Text } from "@chakra-ui/react"
import { useTranslation } from "gatsby-plugin-react-i18next"

import AppStoreButtons from "./AppStoreButtons"
import useAvailableRegion from "../../hooks/useAvailableRegion"

const CallToAction = () => {
  const { t } = useTranslation()
  const { loading, available } = useAvailableRegion()

  return (
    <Box as="section" width="full" backgroundColor="white" textAlign="center">
      <VStack mx="auto" maxW="6xl" px="6" py="14" spacing="6">
        <Text fontSize="1.2rem">
          {t("home-page.cta-section.download-today")}
        </Text>
        {available && (
          <Heading
            size="lg"
            textAlign="center"
            mb="4"
            fontWeight="400"
            color="ahuaYellow.100"
          >
            Download CoffeeClub
          </Heading>
        )}

        <AppStoreButtons
          chakraStyleProps={{
            justifyContent: "center",
            spacing: { base: "4", sm: "6" },
          }}
          loading={loading}
          available={available}
        />
      </VStack>
    </Box>
  )
}

export default CallToAction
