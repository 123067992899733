import React from "react"
import { Box, Text, SimpleGrid, Flex, Heading, Image } from "@chakra-ui/react"
import { useTranslation } from "gatsby-plugin-react-i18next"

import { headerHeight } from "../../utils/constant"
import Feature1Image from "../../assets/images/features/feature-1.svg"
import Feature2Image from "../../assets/images/features/feature-2.svg"
import Feature3Image from "../../assets/images/features/feature-3.svg"

const features = [
  { image: Feature1Image },
  { image: Feature2Image },
  { image: Feature3Image },
]

const KeyFeatures = () => {
  const { t } = useTranslation()

  return (
    <Box
      as="section"
      id="Features"
      sx={{ scrollMarginTop: headerHeight }}
      width="full"
      position="relative"
      backgroundColor="#fff"
    >
      <Box mx="auto" maxW="6xl">
        <SimpleGrid
          px="6"
          py="20"
          columns={{ base: 1, md: 3 }}
          spacing="12"
          textAlign="center"
        >
          {features.map(({ image }, index) => (
            <Flex
              direction="column"
              key={`feature-${index + 1}`}
              alignItems="center"
              px="4"
            >
              <Heading fontWeight="300" mb="6" size="lg" color="ahuaYellow.100">
                {t(`home-page.key-features-section.features.${index}.name`)}
              </Heading>
              <Image
                boxSize="6.5rem"
                filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
                src={image}
                alt={`${t(
                  `home-page.key-features-section.features.${index}.name`
                )} logo`}
                mb="6"
              />
              <Text fontSize="1.2rem">
                {t(
                  `home-page.key-features-section.features.${index}.description`
                )}
              </Text>
            </Flex>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  )
}

export default KeyFeatures
